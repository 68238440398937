<template>
  <div class="container">
    <h3 class="is-size-1">Produits</h3>
    <div class="columns is-gapless">
      <div class="column">
        <b-select class="filters" placeholder="TCG" v-model="selectedTCG" expanded>
          <option value="all">Tous</option>
          <option v-for="tcgame in tcgames" :key="tcgame.id" :value="tcgame.id ">{{ tcgame.name }}</option>
        </b-select>
      </div>
      <div class="column">
        <b-select class="filters" placeholder="Catégorie" v-model="selectedType" expanded>
          <option value="all">Tous</option>
          <option v-for="category in categories" :key="category.id" :value=" category.id ">{{ category.name }}</option>
        </b-select>
      </div>
      <div class="column">
        <b-select class="filters" placeholder="Tri" v-model="selectedSort" expanded>
          <option value=1 key=1>Date au plus récent</option>
          <option value=2 key=2>Date au plus ancien</option>
          <option value=3 key=3>Nom A-Z</option>
          <option value=4 key=4>Nom Z-A</option>
          <option value=5 key=5>Prix croissant</option>
          <option value=6 key=6>Prix décroissant</option>
        </b-select>
      </div>
      <div class="column">
        <b-input class="filters" placeholder="Rechercher" type="search" v-model="recherche"
                 @keyup.enter.native="searchProduct"></b-input>
      </div>
      <div class="column">
        <b-button expanded class="button is-primary" @click="searchProduct">Filtrer</b-button>
      </div>
    </div>
    <div id="cards">
      <div class="card" v-for="product in products" :key="product.id">
        <div class="card-header" style="cursor:pointer;">
          <a class="card-header-title" @click="$router.push({ name: 'getProduct', params: {id: product.id } })">
            {{ product.name }}
          </a>
        </div>
        <div class="card-content">
          <div>
            <figure @click="$router.push({ name: 'getProduct', params: {id: product.id } })" style="cursor:pointer;height: 245px;">
              <img v-if="product.image_path" :src='imageURL(product.image_path)' alt="image of the product"
                   style="height: 15em;">
              <img v-else src="@/assets/logo-arene.png" alt="image of the product">
            </figure>
          </div>
          <div>
            <br>
            <div class="columns is-vcentered">
              <div class="column">
                <strong>{{ Number(product.price).toFixed(2) }} CHF</strong>
              </div>
              <div class="column">
                <b-select v-if="product.stock>0" v-model="product.selectedQuantity"
                          :run="!product.selectedQuantity ? product.selectedQuantity = 1 : true">
                  <option v-for="index in product.stock" :key="index" @click="product.selectedQuantity = index">
                    {{ index }}
                  </option>
                </b-select>
                <b-select v-else-if="product.stock <= 0 && product.is_orderable" v-model="product.selectedQuantity"
                          :run="!product.selectedQuantity ? product.selectedQuantity = 1 : true">
                  <option v-for="index in 100" :key="index" @click="product.selectedQuantity = index">
                    {{ index }}
                  </option>
                </b-select>
              </div>
              <div class="column">
                <b-button v-if="product.stock <= 0 && product.is_orderable == 0" type="is-warning">
                  Produit indisponible
                </b-button>
                <b-button v-else-if="product.stock <= 0 && product.is_orderable == 1" type="is-warning"
                          @click="addCartSelect(product)">
                  Sur commande
                </b-button>
                <b-button v-else-if="checkDateProduct(product)" type="is-success" @click="addCartSelect(product)">
                  Ajouter au panier
                </b-button>
                <b-button v-else type="is-success" @click="addCartSelect(product)">
                  Précommander
                </b-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="section">
      <b-pagination
        :total="pageParams.total"
        v-model="currentPage"
        :per-page="pageParams.per_page"
        :range-before="1"
        :range-after="3"
        order="is-centered">
      </b-pagination>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import {mapGetters} from 'vuex'
import {DateTime} from 'luxon'
import {imageMixin} from '@/mixins/image'
import notification from "@/mixins/notification";

export default {
  name: 'TripleLine',
  data: () => {
    return {
      currentPage: 1,
      products: [],
      selectedProducts: [],
      categories: [],
      tcgames: [],
      selectedTCG: {},
      selectedType: {},
      selectedSort: {},
      recherche: '',
      quantities: [],
      pageParams: {}
    }
  },
  mixins: [imageMixin, notification],
  computed: {
    ...mapGetters(['myOverStock'])
  },
  watch: {
    currentPage: function (val) {
      if (this.currentPage !== this.pageParams.current_page) {
        this.currentPage = val
        this.searchProduct()
      }
    }
  },
  async beforeMount() {
    let myPage = 1
    if (this.$route.query.page > 0) {
      myPage = this.$route.query.page
    }
    await this.$store.dispatch('getProductsFilter', {
      tcg: this.$route.query.games_id,
      category: this.$route.query.category_id,
      tri: this.$route.query.sort,
      name: this.$route.query.name,
      page: myPage
    }).then((data) => {
      this.products = data.data.data
      this.pageParams = data.data
      this.currentPage = this.pageParams.current_page
      for (let i = 0; i < this.products.length; i++) {
        const myDate = new Date(this.products[i].release_date)
        const myYear = myDate.getFullYear()
        const myMonth = myDate.getMonth() + 1
        const myDay = myDate.getDate()
        this.products[i].date = DateTime.local(myYear, myMonth, myDay)
      }
      this.selectedTCG = this.$route.query.games_id
      this.selectedType = this.$route.query.category_id
      this.selectedSort = this.$route.query.sort
      this.recherche = this.$route.query.name
    }).catch(() => {
      this.showError('Impossible de charger les produits.')
    });
    this.$store.dispatch('index', {path: 'categories', data: ''})
      .then(data => {
        this.categories = data.data
      })
    this.$store.dispatch('index', {path: 'games', data: ''})
      .then(data => {
        this.tcgames = data.data
      })
  },
  methods: {
    myAdd(product) {
      this.$store.commit('ADD_PRODUCT', product)
    },
    checkDateProduct(product) {
      const today = DateTime.now()
      if (product.date < today) {
        return true
      }
      if (product.date > today) {
        return false
      }
    },
    searchProduct: function () {
      let request = '/products?'
      if (this.currentPage >= 0) {
        request = request + 'page=' + this.currentPage
      }
      if (this.selectedTCG >= 0) {
        request = request + '&games_id=' + this.selectedTCG
      }
      if (this.selectedType >= 0) {
        request += '&category_id=' + this.selectedType
      }
      if (this.selectedSort >= 0) {
        request += '&sort=' + this.selectedSort
      }
      if (this.recherche != null) {
        request += '&name=' + this.recherche
      }
      router.push(request).catch(() => {
      })
    },
    goProduct: function (id) {
      router.push({
        name: 'getProduct',
        params: id
      }).catch(() => {
      })
    },
    addCart: function (product) {
      this.$toast.clear()
      this.$toast.open({
        message: 'Ajouté au panier',
        type: 'success',
        position: 'top'
      })
      if (this.quantities[product.id] == null) {
        this.myAdd(product)
      } else {
        let i
        for (i = 0; i < this.quantities[product.id]; i++) {
          this.myAdd(product)
        }
      }
    },
    addCartSelect: function (product) {
      if (product.selectedQuantity > 0) {
        let i
        for (i = 0; i < product.selectedQuantity; i++) {
          this.myAdd(product)
        }
      } else {
        this.$toast.clear()
        this.$toast.open({
          message: 'Il faut choisir le nombre à ajouter au panier',
          type: 'warning',
          position: 'top'
        })
        return
      }
      if (this.myOverStock && !product.is_orderable) {
        this.$toast.clear()
        this.$toast.open({
          message: 'Vous ne pouvez pas dépasser le nombre total de produits en stock. Le maximum a été ajouté au panier',
          type: 'warning',
          position: 'top'
        })
      } else {
        this.$toast.clear()
        this.$toast.open({
          message: 'Ajouté au panier',
          type: 'success',
          position: 'top'
        })
      }
    },
    updateQuantity: function (product, quant) {
      if (quant.target.value > product.stock) {
        quant.target.value = product.stock
      }
      if (quant.target.value < 1) {
        quant.target.value = 1
      }
      this.quantities[product.id] = quant.target.value
    }
  }
}

</script>

<style scoped>

#cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  row-gap: 10px;
  column-gap: 10px;
  vertical-align: top;
}

.filters {
  margin-right: 10px;
  margin-bottom: 10px
}

</style>

<template>
  <div class="home">
    <ListProducts :key="listProducts"></ListProducts>
  </div>
</template>
<script>
import ListProducts from '@/components/Products/Products'
export default {
  name: 'Products',
  data () {
    return {
      listProducts: 0
    }
  },
  components: { ListProducts },
  beforeRouteUpdate (to, from, next) {
    this.listProducts += 1
    next()
  }
}
</script>
